import { useCallback, useEffect, useState } from "react";

import { useCopyToClipboard } from "./use-copy-to-clipboard";

export function useCopyWithTimeout(): ReturnType<typeof useCopyToClipboard> {
  const [isCopied, setIsCopied] = useState(false);
  const [copiedText, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    if (!isCopied) return;
    const id = setTimeout(() => setIsCopied(false), 2000);
    return () => clearTimeout(id);
  }, [isCopied, setIsCopied]);

  const copy = useCallback(
    async (text: string) => {
      const hasCopied = await copyToClipboard(text);
      setIsCopied(hasCopied);
      return hasCopied;
    },
    [copyToClipboard],
  );

  return [isCopied ? copiedText : undefined, copy];
}
